<template>
  <div class="invoice-record">
    <div class="table-container">
      <el-table :data="list" ref="multipleTable" style="width: 100%" tooltip-effect="dark">
        <el-table-column label="账单月份" prop="billDate" width="auto"></el-table-column>
        <el-table-column label="账单金额（元）" prop="billAmount" width="auto"></el-table-column>
        <el-table-column label="账单状态" prop="statusName" width="auto"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <router-link :to="'/syoung/sale-credit/detail/'+scope.row.id">
              <el-button class="link" type="text">查看</el-button>
            </router-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="sendData.pageNo"
          :disabled="listLoading"
          :page-size="sendData.pageSize"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { list } from '@/api/sale-credit';
export default {
  name: 'invoice-record',
  data() {
    return {
      list: [],
      sendData: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      listLoading: false
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      const { data: res } = await list(this.sendData);
      this.list = res.list;
      this.total = res.total;
    },
    handleSizeChange(val) {
      this.sendData.pageSize = val;
      this.sendData.pageNo = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.sendData.pageNo = val;
      this.getList();
    }
  }
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.invoice-record {
  padding: 20px;
  .btn-div {
    margin-bottom: 20px;
  }
}
</style>